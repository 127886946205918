.button.is-primary {
  color: white !important;
  background-color: $primary_dark;
}

.button {
  border-radius: 3px !important;
}

#navbarCartItemsCounter {
  background-color: white;
  color: black;
  border-left-color: $primary_dark;
}


.has-background-primary-light {
  background-color: rgba(0, 160, 161, .1) !important
}

.steps:not(.is-hollow) .steps-segment.is-active ~ .steps-segment .steps-marker:not(.is-hollow) {
  background-color: hsl(0deg, 0%, 86%);
  color: rgba(0, 0, 0, 0.7);
}
.steps-segment.is-active .steps-marker:not(.is-hollow) {
  background-color: $primary_dark !important;
}
.steps:not(.is-hollow) .steps-marker:not(.is-hollow) {
  background-color: $primary_dark;
}
.steps-segment:after {
    background-color: $primary_dark;
}

/* Navbar */
nav.navbar {
  height: 80px;

  .navbar-brand {
    .navbar-item {
      img {
	max-height: 42px;
 	margin-bottom: 6px;
      }
    }
  }
}

div {
  .tabs {
    font-size: .9rem;
    height: auto !important;

    a:hover {
      border-bottom-color: $primary_dark;
    }

    .is-active {
      background-color: $primary_dark;

      a {
        border-bottom: 0;
        color: white;
      }

    }
  }
}

.footer {
  .menu {
    font-size: 1.25rem;
  }
}
